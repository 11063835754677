import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import ResponsiveDialog from '../../utility/ResponsiveDialog';
import AlertDialogContents from './AlertDialogContents';

import * as types from '../../../actions/actionTypes';
import styles from '../../../styles/sass/components/AlertDialog.module.scss';

export interface Props {
  heading: string;
  subText: any;
  dispatch: any;
  open: boolean;
}

export const AlertDialog: React.FC<Props> = props => {
  const handleClose = () => {
    if (props.open) {
      props.dispatch({ type: types.CloseAlertDialog });
    }
  };

  const { heading, subText, open } = props;

  return (
    <ResponsiveDialog
      onClose={handleClose}
      open={open}
      className={styles.alertDialog}
      buttonClassName={styles.alertDialogCloseButton}
    >
      <AlertDialogContents
        closeModal={handleClose}
        heading={heading}
        subText={subText}
      />
    </ResponsiveDialog>
  );
};

const mapStateToProps = (state: any) => ({
  heading: state.dialogs.alertDialogHeading,
  subText: state.dialogs.alertDialogSubText,
  open: state.dialogs.alertDialogOpen,
});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
