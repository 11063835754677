import * as React from 'react';
import Link from 'next/link';
import { withRouter } from 'next/router';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { buttonStyles } from '../../../styles/base';

import styles from '../../../styles/sass/components/AlertDialog.module.scss';

export interface Props {
  classes: any;
  closeModal: Function;
  heading: string;
  subText: string;
  router: any;
}

export const AlertDialogContents: React.FC<Props> = props => {
  const handleClick = () => {
    props.closeModal();
    // Used setTimeout in order to close the modal and navigate to contact page
    setTimeout(() => {
      props.router.push({
        pathname: '/contact',
      });
    }, 0);
  };

  const { classes, closeModal, heading, subText } = props;
  const subTextComponent =
    subText === 'showing' ? (
      <span>
        Unfortunately, Torii is currently unable to show this property due to
        our{' '}
        <Link href="/buying-with-torii#10">
          <a target="_blank">minimum price limit</a>
        </Link>
        .
      </span>
    ) : subText === 'offer' ? (
      <span>
        Unfortunately, Torii is currently unable to submit an offer on this
        property due to our{' '}
        <Link href="/buying-with-torii#10">
          <a target="_blank">minimum price limit</a>
        </Link>
        .
      </span>
    ) : (
      subText
    );
  return (
    <div
      className={`col-xs-12 row center-xs middle-xs ${styles.alertDialogContainer}`}
    >
      <div className={styles.alertDialogInner}>
        <h2 className={styles.heading}>{heading}</h2>
        <br />
        <br />
        <p className={styles.copyText}>{subTextComponent}</p>
        <br />
        <p className={styles.copyText}>
          If you're still interested in a showing,{' '}
          <a onClick={handleClick}>click here to contact us</a> and we will
          connect you with one of our local partner agents who will be able to
          assist you.
        </p>
        <br />
        <br />
        <Button
          className={`${styles.doneButton} ${classes.buttonMediumPink}`}
          color="secondary"
          onClick={() => closeModal()}
          variant="contained"
        >
          DONE
        </Button>
      </div>
    </div>
  );
};

export default withStyles(buttonStyles)(withRouter(AlertDialogContents));
